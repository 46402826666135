export interface ScreenshotsConfig {
  resources?: string[]
  elements?: (ICommonBackground | IBackground | IText | IDevice | IImage)[]
}

export enum Component {
  COMMON_BACKGROUND = 'COMMON_BACKGROUND',
  BACKGROUND = 'BACKGROUND',
  TEXT = 'TEXT',
  IMAGE = 'IMAGE',
  DEVICE = 'DEVICE',
}

export interface ICommonBackground {
  type: Component
  id: string
  background: string
}

export interface IBackground {
  type: Component
  id: string
  background: string
  colorName: string
}

export interface IText {
  type: Component
  id: string
  x: number
  y: number
  width: number
  height: number
  angle: number
  value: string
  color: string
  colorName: string
  fontFamily: string
  fontWeight: string
  fontStyle: string
  fontSize: string
  textAlign: string
  verticalAlign: string
  lineHeight: string
  letterSpacing: string
}

export interface IDevice {
  type: Component
  id: string
  x: number
  y: number
  width: number
  height: number
  angle: number
  deviceType: Device
  screenshotName: string
  image: string
  color: string
  colorName: string
}

export interface IImage {
  type: Component
  id: string
  x: number
  y: number
  width: number
  height: number
  angle: number
  url: string
}

export const containerHeight = 668.594,
  backgroundWidth = 309.062,
  marginWidth = 21.078,
  minWidth = 20,
  minHeight = 20

export enum Mode {
  POINTER = 'pointer',
  ADD_TEXT = 'addText',
  ADD_MEDIA = 'addMedia',
}

export enum Device {
  iPhone13Pro = 'iPhone13Pro',
  iPhone14Pro = 'iPhone14Pro',
}

export const iPhone13ProWidth = 208,
  iPhone13ProHeight = 426,
  iPhone14ProWidth = 210,
  iPhone14ProHeight = 432.45852187
