import { Box } from '@mui/material'
import { memo, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import * as styled from './Record.styled'

import { Status } from 'components'
import { CMSContext, ProgressContext } from 'context'
import { CMSCollectionRecord, CMSService, collectionIdToFind, extractRecord } from 'services/cms'

interface Props {
  name?: string
  logicItem?: boolean
  value?: string
  dropDown?: JSX.Element
  record?: CMSCollectionRecord
}

export const Record: React.FC<Props> = memo(({ name, logicItem, value, dropDown, record: realRecord }) => {
  const { allCollections } = useContext(CMSContext)
  const { id } = useParams()
  const { toast } = useContext(ProgressContext)
  const [record, setRecord] = useState(realRecord)
  const { collectionId, recordId } = value ? extractRecord(value) : { collectionId: undefined, recordId: undefined }

  useEffect(() => {
    if (!realRecord && collectionId && recordId) {
      const getRecord = () =>
        CMSService.getRecord(id as string, collectionId, recordId)
          .then(res => setRecord(res.data))
          .catch(err => toast(err))
      getRecord()
      const refreshEventName = `ReferenceItem/${collectionId}/${recordId}`
      document.addEventListener(refreshEventName, getRecord)
      return () => document.removeEventListener(refreshEventName, getRecord)
    }
  }, [collectionId, recordId])

  const currentRef = collectionId
    ? allCollections.find(el => collectionIdToFind(el.id) === collectionIdToFind(collectionId))
    : null
  const titleField = currentRef?.titleField

  return (
    <>
      <styled.RecordHead>
        {logicItem ? name : collectionId}
        <Box display="flex" gap="15px" alignItems="center">
          {!logicItem && record ? <Status status={record.recordStatus} /> : <div />}
          {dropDown}
        </Box>
      </styled.RecordHead>
      <styled.RecordContent>
        {record && <p>{titleField ? record[titleField] || record.id : record.id}</p>}
      </styled.RecordContent>
    </>
  )
})
