import { Box } from '@mui/material'
import React, { useState } from 'react'

import { CustomSection, CustomSectionsWrapper, Switch, SwitchVariant, Text, Type } from 'components'
import { DeploymentStatus, Links, SettingsLayout } from '../components'
import { AdditionalSettings, ProductionCredentials, TestCredentials } from './sections'

export const Stripe: React.FC = () => {
  const [enabled, setEnabled] = useState(true)

  return (
    <SettingsLayout>
      <Text type={Type.TITLE}>Stripe</Text>
      <Text type={Type.SUB_TITLE}>
        Accept payments in your app with Stripe integration. This also enables credit card, Google Pay, and Apple Pay.
        Setup is easy and only requires a few extra steps to get started.
      </Text>
      <Links />
      <CustomSectionsWrapper>
        <CustomSection style={{ padding: '0px' }}>
          <Box display="flex" justifyContent="space-between" alignItems="center" padding="20px 24px">
            <Text type={Type.H3} as="span">
              Enable Stripe Payments
            </Text>
            <Switch checked={enabled} onChange={setEnabled} variant={SwitchVariant.BIG} />
          </Box>
        </CustomSection>
        <DeploymentStatus />
        <TestCredentials />
        <ProductionCredentials />
        <AdditionalSettings />
      </CustomSectionsWrapper>
    </SettingsLayout>
  )
}
