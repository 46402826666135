import React, { useRef, useState } from 'react'

import * as styled from './AIModal.styled'

import { DropDown, Icon, Name } from 'components'
import { useOnClickOutside, useParamsFromUrl } from 'hooks'

interface Props {
  input?: boolean
  whiteIcon?: boolean
}

export const AIIconBtn: React.FC<Props> = ({ input, whiteIcon }) => {
  const [open, setOpen] = useState(false)
  const { setIntentToUrl } = useParamsFromUrl()
  const ref = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, () => setOpen(false))

  const options = [
    {
      label: 'Generate components',
      iconName: Name.PICKERS_GENERATE_COMPONENTS,
      onClick: () => setIntentToUrl('generate-component'),
    },
    {
      label: 'Refactor',
      iconName: Name.PICKERS_REFACTOR,
      onClick: () => setIntentToUrl('refactor'),
    },
    {
      label: 'Generate content',
      iconName: Name.PICKERS_GENERATE_CONTENT,
      onClick: () => setIntentToUrl('generate-content'),
    },
    {
      label: 'Explain this / Q&A',
      iconName: Name.PICKERS_QA,
      onClick: () => setIntentToUrl('explain'),
    },
  ].filter((el, i) => (input ? [2, 3].includes(i) : true))

  return (
    <div ref={ref}>
      <DropDown
        top="20px"
        right="0px"
        width="220px"
        options={options}
        outerOpenState={open}
        onClick={() => setOpen(open => !open)}
        labelElement={
          <styled.AIIcon open={open} whiteIcon={whiteIcon}>
            <Icon name={Name.ADDITIONAL_AI_SMALL} onClick={() => setOpen(open => !open)} />{' '}
          </styled.AIIcon>
        }
      />
    </div>
  )
}
