export const constants = [
  {
    path: 'Paywall/Paywall',
    backgroundColor: {
      constant: '@surfaceVariant',
    },
    components: [
      {
        componentType: 'overlay',
        id: 'e114e81d-1cbd-4a08-acd1-17ee51ff05e3',
        subComponents: [
          {
            componentAlignment: 'fill',
            componentsDirection: 'vertical',
            componentsSpacing: {
              constant: '20',
            },
            componentType: 'stack',
            contentPadding: {
              constant: {
                bottom: '@bottomScreenMargins',
                leading: '24',
                trailing: '24',
              },
            },
            id: 'f4f0d91b-8ff2-40c8-86b8-125d6ccac1b0',
            subComponents: [
              {
                componentAlignment: 'fill',
                componentsDirection: 'vertical',
                componentType: 'scroll',
                contentPadding: {
                  constant: {
                    top: '@topScreenMargins',
                  },
                },
                id: 'b4dac967-a730-42f3-94d5-ab3b5e4a49ce',
                showScrollBar: {
                  constant: false,
                },
                subComponents: [
                  {
                    componentsDirection: 'vertical',
                    componentsSpacing: {
                      constant: '8',
                    },
                    componentType: 'stack',
                    id: '06fb3455-5f16-401d-b895-b01828ca9ab5',
                    subComponents: [
                      {
                        componentAlignment: 'fill',
                        componentType: 'image',
                        height: {
                          constant: '164',
                        },
                        id: 'bc7e6fa6-dc16-4e20-8cc7-482f5250f1a7',
                        image: {
                          constant: {
                            resourceId: 'w932mh3rrarvuezsgv6h',
                          },
                        },
                        imageLayout: 'fit',
                      },
                      {
                        componentAlignment: 'fill',
                        componentType: 'text',
                        font: '@Big Title Bold',
                        id: 'af1ef60a-7c97-492f-9a40-f6e993a9340f',
                        text: {
                          key: 'paywall.title',
                          locales: {
                            en: 'Premium access',
                          },
                        },
                        textAlignment: 'leading',
                        textColor: {
                          constant: '@onBackground',
                        },
                      },
                      {
                        componentAlignment: 'fill',
                        componentsDirection: 'horizontal',
                        componentsSpacing: {
                          constant: '10',
                        },
                        componentType: 'stack',
                        id: '74963c33-5724-4fe4-a6b0-31b80765bd86',
                        subComponents: [
                          {
                            componentAlignment: 'center',
                            componentType: 'image',
                            height: {
                              constant: '16',
                            },
                            id: 'be5848ad-3f91-4b53-b853-a320b78e1f73',
                            image: {
                              constant: {
                                resourceId: '7c845a85-74f4-42ae-80d1-2f45e699d66d',
                              },
                            },
                            imageColor: {
                              constant: '@onBackground',
                            },
                            imageLayout: 'fit',
                            width: {
                              constant: '16',
                            },
                          },
                          {
                            componentAlignment: 'center',
                            componentType: 'text',
                            font: '@Title 3',
                            id: 'e8fbf177-6cc0-4446-9708-bf3342871eb0',
                            text: {
                              key: 'paywall.item.1',
                              locales: {
                                en: 'Dozens of premium templates',
                              },
                            },
                            textAlignment: 'leading',
                            textColor: {
                              constant: '@onBackground',
                            },
                          },
                        ],
                      },
                      {
                        componentAlignment: 'fill',
                        componentsDirection: 'horizontal',
                        componentsSpacing: {
                          constant: '10',
                        },
                        componentType: 'stack',
                        id: '08b84d66-d1ed-4b8f-ad36-d0f6f826e594',
                        subComponents: [
                          {
                            componentAlignment: 'center',
                            componentType: 'image',
                            height: {
                              constant: '16',
                            },
                            id: '91eeba51-e966-415e-85b9-84749d79cfd0',
                            image: {
                              constant: {
                                resourceId: '7c845a85-74f4-42ae-80d1-2f45e699d66d',
                              },
                            },
                            imageColor: {
                              constant: '@onBackground',
                            },
                            imageLayout: 'fit',
                            width: {
                              constant: '16',
                            },
                          },
                          {
                            componentAlignment: 'center',
                            componentType: 'text',
                            font: '@Title 3',
                            id: '1524ffed-ea84-438d-b78a-880b854091e0',
                            text: {
                              key: 'paywall.item.2',
                              locales: {
                                en: 'Exclusive fonts and stickers',
                              },
                            },
                            textAlignment: 'leading',
                            textColor: {
                              constant: '@onBackground',
                            },
                          },
                        ],
                      },
                      {
                        componentAlignment: 'fill',
                        componentsDirection: 'horizontal',
                        componentsSpacing: {
                          constant: '10',
                        },
                        componentType: 'stack',
                        id: '10febbf3-00e6-4efb-a30a-805507be8a45',
                        subComponents: [
                          {
                            componentAlignment: 'center',
                            componentType: 'image',
                            height: {
                              constant: '16',
                            },
                            id: 'b6f75d8b-e883-4352-b2d6-028c1aaf5215',
                            image: {
                              constant: {
                                resourceId: '7c845a85-74f4-42ae-80d1-2f45e699d66d',
                              },
                            },
                            imageColor: {
                              constant: '@onBackground',
                            },
                            imageLayout: 'fit',
                            width: {
                              constant: '16',
                            },
                          },
                          {
                            componentAlignment: 'center',
                            componentType: 'text',
                            font: '@Title 3',
                            id: 'e96ea731-5f26-4442-8707-c13f46be0ec7',
                            text: {
                              key: 'paywall.item.3',
                              locales: {
                                en: 'No design skills required',
                              },
                            },
                            textAlignment: 'leading',
                            textColor: {
                              constant: '@onBackground',
                            },
                          },
                        ],
                      },
                    ],
                    componentAlignment: 'fill',
                  },
                ],
              },
              {
                backgroundColor: {
                  constant: '@onPrimary',
                },
                borderColor: {
                  constant: '@primary',
                },
                componentAlignment: 'fill',
                componentType: 'product',
                name: 'product.first',
                id: '895824c1-0642-4727-8dca-55f5fc69c53d',
                product: 'purchase.recurrent.1',
                selected: {
                  constant: true,
                },
                showDiscount: {
                  constant: true,
                },
              },
              {
                backgroundColor: {
                  constant: '@onPrimary',
                },
                borderColor: {
                  constant: '@primary',
                },
                componentAlignment: 'fill',
                componentType: 'product',
                name: 'product.second',
                id: 'f6a17fdb-ab63-4a33-8939-c51da1a46b44',
                product: 'purchase.permanent.1',
              },
              {
                backgroundColor: {
                  constant: '@onPrimary',
                },
                borderColor: {
                  constant: '@primary',
                },
                componentAlignment: 'fill',
                componentType: 'product',
                name: 'product.third',
                id: 'b567b895-59b1-4889-a3dc-e7c075d5ea3e',
                product: 'subscription.month.1',
              },
              {
                id: '642f42b2-8f72-4647-a8d0-6fe3c6dc6720',
                name: 'button.continue',
                componentType: 'button',
                textAlignment: 'center',
                font: '@Body Bold',
                height: {
                  constant: '@buttonHeight',
                },
                text: {
                  key: 'paywall.pay',
                  locales: {
                    en: 'Continue',
                  },
                },
                componentAlignment: 'fill',
                actions: [
                  {
                    actionType: 'purchaiseSelected',
                    id: 'e1aa0956-173d-4633-9b7e-a4732a96f26c',
                  },
                  {
                    actionType: 'closePurchases',
                    id: 'efe0f29d-3578-40d1-9c61-6d3b38523909',
                  },
                ],
                margins: {
                  top: '10',
                },
                backgroundColor: {
                  constant: '@primary',
                },
                textColor: {
                  constant: '@onPrimary',
                },
                cornersRadius: {
                  constant: '8',
                },
              },
              {
                componentAlignment: 'fill',
                componentType: 'overlay',
                id: '7ea66155-2a94-4421-bee7-7b00d6d87e04',
                subComponents: [
                  {
                    componentAlignment: 'center',
                    componentsDirection: 'horizontal',
                    componentsSpacing: {
                      constant: '20',
                    },
                    componentType: 'stack',
                    id: '46a1e162-dd6d-451a-a21e-cd56b8140ca6',
                    subComponents: [
                      {
                        actions: [
                          {
                            actionType: 'restorePurchases',
                            id: '95cb1f7d-0879-4726-ab19-23592a5d3e33',
                          },
                          {
                            actionType: 'closePurchases',
                            id: 'f541c0dc-5e2b-43ed-bc3d-964259495cf2',
                          },
                        ],
                        componentAlignment: 'fill',
                        name: 'button.restorePurchases',
                        componentType: 'button',
                        font: '@Subheadline',
                        id: '00a45488-fffd-48eb-8354-b1f69def2a33',
                        text: {
                          key: 'paywall.restore',
                          locales: {
                            en: 'Restore purchases',
                          },
                        },
                        textAlignment: 'center',
                        textColor: {
                          constant: '@onBackground',
                        },
                      },
                      {
                        actions: [
                          {
                            actionType: 'openWeb',
                            id: '3f0978b2-385c-445f-813a-e951a4ef738e',
                            options: {
                              link: {
                                constant: 'https://www.codeplatform.io/terms-and-policies',
                              },
                            },
                          },
                        ],
                        componentAlignment: 'fill',
                        name: 'button.terms',
                        componentType: 'button',
                        font: '@Subheadline',
                        id: '4f7ffa07-b89e-43a5-ab0e-47a56a3e042e',
                        text: {
                          key: 'paywall.terms',
                          locales: {
                            en: 'Terms & Policies',
                          },
                        },
                        textAlignment: 'center',
                        textColor: {
                          constant: '@onBackground',
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            componentAlignment: 'top',
            componentsDirection: 'horizontal',
            componentType: 'stack',
            contentPadding: {
              constant: {
                top: '@topScreenMargins',
              },
            },
            id: 'c257a874-a2d9-4ab9-be75-1672c2ed2003',
            subComponents: [
              {
                componentAlignment: 'fill',
                componentType: 'view',
                id: 'd3a7fbbd-a88a-4127-9d6c-027534d40a0c',
              },
              {
                actions: [
                  {
                    actionType: 'closePurchases',
                    id: 'ed5d293f-256a-4ed8-9d8c-96c6547a0099',
                  },
                ],
                componentAlignment: 'fill',
                name: 'button.close',
                componentType: 'button',
                height: {
                  constant: '40',
                },
                id: 'ae8374d6-2334-4f90-a9f4-26af2b888cc6',
                image: {
                  constant: {
                    resourceId: '6fbed299-8aa2-4a2c-b919-6d2acdf864c3',
                  },
                },
                imageColor: {
                  constant: '@onBackground',
                },
                width: {
                  constant: '40',
                },
              },
            ],
          },
        ],
        componentAlignment: 'fill',
      },
    ],
    enabled: true,
    id: '24a5bcd8-ddc8-48a7-9d8d-0888ef392bb5',
    module: 'paywall',
    screenName: 'Purchases',
    size: 'compact',
    showTopBar: {
      constant: false,
    },
  },
  {
    path: 'Onboarding/Before you join',
    backgroundColor: {
      constant: '@background',
    },
    components: [
      {
        componentsDirection: 'vertical',
        componentType: 'stack',
        id: '122a280f-050b-4f4d-a3d0-1e316c4246dd',
        margins: {
          bottom: '@bottomScreenMargins',
          leading: '@leadingScreenMargins',
          trailing: '@trailingScreenMargins',
        },
        subComponents: [
          {
            componentAlignment: 'fill',
            componentsDirection: 'vertical',
            componentType: 'scroll',
            contentPadding: {
              constant: {
                top: '@topScreenMargins',
              },
            },
            id: '1e9ac613-85ee-4e77-9237-e56cbe441351',
            subComponents: [
              {
                componentsDirection: 'vertical',
                componentsSpacing: {
                  constant: '16',
                },
                componentType: 'stack',
                contentPadding: {
                  constant: {
                    leading: '24',
                    top: '24',
                    trailing: '24',
                  },
                },
                id: '1dfdbcff-a535-4e6f-9ee7-def41bc8ce1c',
                subComponents: [
                  {
                    componentAlignment: 'fill',
                    componentType: 'image',
                    id: 'ef848738-baef-4c40-aa94-5b40a76e70e1',
                    image: {
                      constant: {
                        resourceId: 'rhrf56jtzemyehzdvual',
                      },
                    },
                    imageLayout: 'center',
                    name: 'image.main',
                    margins: {
                      leading: '60',
                      trailing: '60',
                    },
                  },
                  {
                    componentAlignment: 'fill',
                    componentType: 'text',
                    font: '@Title 2 Bold',
                    id: '0d39736a-3842-4c9f-adbc-86c37af523db',
                    name: 'label.title',
                    text: {
                      key: 'beforeyoujoin.label.text',
                      locales: {
                        en: 'Before you join',
                      },
                    },
                    textAlignment: 'leading',
                    textColor: {
                      constant: '@onBackground',
                    },
                  },
                  {
                    componentAlignment: 'fill',
                    componentType: 'text',
                    font: '@Body',
                    id: '00d8fc3c-3835-4b1a-9d08-8a4a797dd457',
                    name: 'text.description',
                    text: {
                      key: 'beforeyoujoin.text.description',
                      locales: {
                        en: 'Please review our Terms and Policies. By tapping “Accept,” you agree to our policies.',
                      },
                    },
                    textAlignment: 'leading',
                    textColor: {
                      constant: '@onBackground',
                    },
                  },
                ],
                componentAlignment: 'fill',
              },
            ],
            margins: {
              bottom: '10',
            },
          },
          {
            componentAlignment: 'fill',
            componentsDirection: 'vertical',
            componentsSpacing: {
              constant: '16',
            },
            componentType: 'stack',
            contentPadding: {
              constant: {
                bottom: '16',
                leading: '24',
                trailing: '24',
              },
            },
            id: '26fed02e-7fe6-453e-bd82-3630978eb49c',
            subComponents: [
              {
                actions: [
                  {
                    actionType: 'acceptTerms',
                    id: '98b1818d-9130-4faf-8178-9d674be12d69',
                  },
                  {
                    actionType: 'navigate',
                    id: '76baee7a-13aa-47b8-8471-bb8a5ba1cbd4',
                    options: {
                      defaultTarget: 'Authentication',
                    },
                  },
                ],
                backgroundColor: {
                  constant: '@primary',
                },
                componentAlignment: 'fill',
                componentType: 'button',
                cornersRadius: {
                  constant: '@cornersRadius',
                },
                font: '@Body Bold',
                height: {
                  constant: '@buttonHeight',
                },
                id: '0352f826-208d-4bb0-baf5-3aa9013a5ff5',
                name: 'button.accept',
                text: {
                  key: 'beforeyoujoin.button.accept',
                  locales: {
                    en: 'Accept',
                  },
                },
                textAlignment: 'center',
                textColor: {
                  constant: '@onPrimary',
                },
              },
              {
                actions: [
                  {
                    actionType: 'show',
                    id: 'c4686aa4-b96e-4148-945c-c8e6c661884f',
                    options: {
                      defaultTarget: 'Onboarding',
                    },
                  },
                ],
                backgroundColor: {
                  constant: '@background',
                },
                componentAlignment: 'fill',
                componentType: 'button',
                font: '@Body',
                height: {
                  constant: '@buttonHeight',
                },
                id: 'ad545aae-12ff-4f6b-95aa-a4501891b5c9',
                name: 'button.cancel',
                text: {
                  key: 'beforeyoujoin.button.cancel',
                  locales: {
                    en: 'Cancel sign up',
                  },
                },
                textAlignment: 'center',
                textColor: {
                  constant: '@primary',
                },
              },
            ],
          },
        ],
        componentAlignment: 'fill',
      },
    ],
    enabled: true,
    id: '72a2f673-2c73-4929-aac9-9b5f7ba21710',
    module: 'beforeJoin',
    screenName: 'Onboarding Terms',
    size: 'compact',
    showBackButton: {
      constant: false,
    },
    showTopBar: {
      constant: false,
    },
  },
  {
    path: 'Onboarding/Walkthrough',
    backgroundColor: {
      constant: '@background',
    },
    components: [
      {
        componentType: 'carousel',
        duration: 5,
        id: '89cd9c7f-8f09-4283-82ac-814bd8e23ea5',
        margins: {
          bottom: '@bottomScreenMargins',
          leading: '@leadingScreenMargins',
          top: '@topScreenMargins',
          trailing: '@trailingScreenMargins',
        },
        name: 'onboarding.carousel',
        subComponents: [
          {
            componentsDirection: 'vertical',
            componentType: 'stack',
            id: '4925e255-f2f1-425e-bc7e-6818576c5f74',
            margins: {
              bottom: '16',
              leading: '24',
              top: '16',
              trailing: '24',
            },
            name: 'slide_1',
            subComponents: [
              {
                componentAlignment: 'fill',
                componentType: 'overlay',
                height: {
                  constant: '140',
                },
                id: '6df22e4e-1355-4823-a815-b4453e492bb8',
                subComponents: [
                  {
                    componentAlignment: 'top',
                    componentType: 'text',
                    font: '@Title 1 Bold',
                    id: '9b2da120-8288-436e-81c0-8cccb97948ab',
                    name: 'label.title',
                    text: {
                      key: 'onboarding.label.title',
                      locales: {
                        en: 'Welcome to App Starter by CodePlatform',
                      },
                    },
                    textAlignment: 'leading',
                    textColor: {
                      constant: '@onBackground',
                    },
                    margins: {
                      top: '30',
                    },
                  },
                ],
              },
              {
                componentAlignment: 'fill',
                componentType: 'image',
                id: '0d20ff56-80ef-42fd-b5c7-2bf516f4fbbf',
                image: {
                  constant: {
                    resourceId: 'blfelods2ydfimryo6ml',
                  },
                },
                imageLayout: 'fit',
                name: 'image.slideimage',
                cornersRadius: {
                  constant: '30',
                },
                margins: {
                  leading: '60',
                  trailing: '60',
                  top: '60',
                  bottom: '60',
                },
              },
              {
                id: '585fcf7a-b540-4ea8-9b9c-d925fe3bf1ab',
                name: 'spacer 1',
                componentType: 'spacer',
                componentAlignment: 'fill',
              },
              {
                actions: [
                  {
                    actionType: 'navigate',
                    id: 'd90d69c1-f47d-4c83-b491-c14d0d7da0b0',
                    options: {
                      defaultTarget: 'Onboarding Terms',
                    },
                  },
                ],
                backgroundColor: {
                  constant: '@primary',
                },
                componentAlignment: 'fill',
                componentType: 'button',
                cornersRadius: {
                  constant: '@cornersRadius',
                },
                font: '@Body Bold',
                height: {
                  constant: '@buttonHeight',
                },
                id: '3fb814ce-cdaa-4425-8682-6a9bd6f9b262',
                name: 'button.getstarted',
                text: {
                  key: 'onboarding.button.getstarted',
                  locales: {
                    en: 'Get started',
                  },
                },
                textAlignment: 'center',
                textColor: {
                  constant: '@onPrimary',
                },
              },
            ],
            componentAlignment: 'fill',
          },
          {
            componentsDirection: 'vertical',
            componentType: 'stack',
            id: '89e1ba75-4d0a-410a-8e9e-e693b48c8c75',
            margins: {
              bottom: '16',
              leading: '24',
              top: '16',
              trailing: '24',
            },
            name: 'slide_1',
            subComponents: [
              {
                componentAlignment: 'fill',
                componentType: 'overlay',
                height: {
                  constant: '140',
                },
                id: '0d8c5247-80af-46ff-9bf5-1352a6b677d4',
                subComponents: [
                  {
                    componentAlignment: 'top',
                    componentType: 'text',
                    font: '@Title 1 Bold',
                    id: 'e636286d-8e09-40cf-8049-5f28277f707d',
                    name: 'label.title',
                    text: {
                      key: 'onboarding.label.title',
                      locales: {
                        en: 'Seamless Integration, Ultimate User Experience',
                      },
                    },
                    textAlignment: 'leading',
                    textColor: {
                      constant: '@onBackground',
                    },
                    margins: {
                      top: '30',
                    },
                  },
                ],
              },
              {
                componentAlignment: 'fill',
                componentType: 'image',
                id: '05868959-e47e-4e61-98e8-8c602c433905',
                image: {
                  constant: {
                    resourceId: 'lfzyrkhxebrsr60uojkj',
                  },
                },
                imageLayout: 'fit',
                name: 'image.slideimage',
                cornersRadius: {
                  constant: '30',
                },
                margins: {
                  leading: '60',
                  top: '60',
                  trailing: '60',
                  bottom: '60',
                },
              },
              {
                id: 'cb0dd708-0b53-47e8-bc47-12fa00d89160',
                name: 'spacer 2',
                componentType: 'spacer',
                componentAlignment: 'fill',
              },
              {
                actions: [
                  {
                    actionType: 'navigate',
                    id: 'd90d69c1-f47d-4c83-b491-c14d0d7da0b0',
                    options: {
                      defaultTarget: 'Onboarding Terms',
                    },
                  },
                ],
                backgroundColor: {
                  constant: '@primary',
                },
                componentAlignment: 'fill',
                componentType: 'button',
                cornersRadius: {
                  constant: '@cornersRadius',
                },
                font: '@Body Bold',
                height: {
                  constant: '@buttonHeight',
                },
                id: 'b829da26-6232-4699-a701-bc7c6b9edff5',
                name: 'button.getstarted',
                text: {
                  key: 'onboarding.button.getstarted',
                  locales: {
                    en: 'Get started',
                  },
                },
                textAlignment: 'center',
                textColor: {
                  constant: '@onPrimary',
                },
              },
            ],
            componentAlignment: 'fill',
          },
          {
            componentsDirection: 'vertical',
            componentType: 'stack',
            id: '0d58c9ba-dfe7-46fc-b6a3-1395801cf99a',
            margins: {
              bottom: '16',
              leading: '24',
              top: '16',
              trailing: '24',
            },
            name: 'slide_1',
            subComponents: [
              {
                componentAlignment: 'fill',
                componentType: 'overlay',
                height: {
                  constant: '140',
                },
                id: 'abb258ba-a62b-45ca-aac5-7b13c6677811',
                subComponents: [
                  {
                    componentAlignment: 'top',
                    componentType: 'text',
                    font: '@Title 1 Bold',
                    id: 'e77d1737-eada-4d51-8ebb-c88df21c128a',
                    name: 'label.title',
                    text: {
                      key: 'onboarding.label.title',
                      locales: {
                        en: 'Discover More, Achieve More, With Ease',
                      },
                    },
                    textAlignment: 'leading',
                    textColor: {
                      constant: '@onBackground',
                    },
                    margins: {
                      top: '30',
                    },
                  },
                ],
              },
              {
                componentAlignment: 'fill',
                componentType: 'image',
                id: 'a75e0693-a853-42c4-b616-6e22224760fe',
                image: {
                  constant: {
                    resourceId: 'pdxpzn9xfsq5ynh77blw',
                  },
                },
                imageLayout: 'fit',
                name: 'image.slideimage',
                cornersRadius: {
                  constant: '30',
                },
                margins: {
                  trailing: '60',
                  top: '60',
                  leading: '60',
                  bottom: '60',
                },
              },
              {
                id: '8f573998-08e8-4230-ab3e-c68137851dd1',
                name: 'spacer 3',
                componentType: 'spacer',
                componentAlignment: 'fill',
              },
              {
                actions: [
                  {
                    actionType: 'navigate',
                    id: 'd90d69c1-f47d-4c83-b491-c14d0d7da0b0',
                    options: {
                      defaultTarget: 'Onboarding Terms',
                    },
                  },
                ],
                backgroundColor: {
                  constant: '@primary',
                },
                componentAlignment: 'fill',
                componentType: 'button',
                cornersRadius: {
                  constant: '@cornersRadius',
                },
                font: '@Body Bold',
                height: {
                  constant: '@buttonHeight',
                },
                id: 'ca59cf2f-7cde-487e-8b4f-4f0e4bf83bbb',
                name: 'button.getstarted',
                text: {
                  key: 'onboarding.button.getstarted',
                  locales: {
                    en: 'Get started',
                  },
                },
                textAlignment: 'center',
                textColor: {
                  constant: '@onPrimary',
                },
              },
            ],
            componentAlignment: 'fill',
          },
        ],
        componentAlignment: 'fill',
      },
    ],
    enabled: true,
    id: '3b8ed07c-20c4-4dd1-ac75-fda712153c0e',
    module: 'onboarding',
    screenName: 'Onboarding',
    size: 'compact',
    showBackButton: {
      constant: false,
    },
    showTopBar: {
      constant: false,
    },
  },
]
