import { Box } from '@mui/material'
import React, { useContext } from 'react'

import * as styled from './TopPanel.styled'

import { ProjectContext, TopPanelContext } from 'context'
import { useTasks } from 'hooks'
import { LogicItems, StoreManager } from 'pages'
import { DeployBtn, Navigation, ProfileBtn, RunBtn } from './components'

interface Props {
  isMobile: boolean
}

export const TopPanel: React.FC<Props> = ({ isMobile }) => {
  const {
    backButton,
    leftButtons,
    pageLeftButtons,
    pageCenterButtons,
    rightButtons,
    page,
    openStoreManager,
    setOpenStoreManager,
    openLogicItems,
    setOpenLogicItems,
  } = useContext(TopPanelContext)
  const {
    project: { name, logoUrl },
  } = useContext(ProjectContext)
  const { tasks, runningTasks, failedTasks, setupSteps } = useTasks()

  return (
    <>
      <styled.Wrapper hasBackButton={!!backButton} isMobile={isMobile}>
        {isMobile ? (
          <>
            <styled.Buttons>
              <styled.Logo>
                <img src={logoUrl} alt="logo" />
              </styled.Logo>
              <styled.Name>{name}</styled.Name>
            </styled.Buttons>
            <styled.Buttons right>
              <RunBtn />
            </styled.Buttons>
          </>
        ) : (
          <>
            {backButton && <styled.BackButton>{backButton}</styled.BackButton>}
            <styled.Buttons>{pageLeftButtons || leftButtons}</styled.Buttons>
            <Box
              display="flex"
              justifyContent="center"
              position="relative"
              ml={!!pageCenterButtons ? '-109px' : !!backButton ? '-49px' : undefined}
            >
              {pageCenterButtons || <Navigation tasks={tasks} runningTasks={runningTasks} failedTasks={failedTasks} />}
            </Box>
            {rightButtons && <styled.Buttons right>{rightButtons}</styled.Buttons>}
            <styled.Buttons right hidden={!!rightButtons}>
              <ProfileBtn />
              <RunBtn />
              <DeployBtn setOpenStoreManager={setOpenStoreManager} tasks={tasks} setupSteps={setupSteps} />
            </styled.Buttons>
          </>
        )}
      </styled.Wrapper>
      {openStoreManager && <StoreManager close={() => setOpenStoreManager(false)} />}
      {openLogicItems && <LogicItems name={openLogicItems} close={() => setOpenLogicItems(null)} />}
      {page && <styled.Page>{page}</styled.Page>}
    </>
  )
}

export const EmptyTopPanel: React.FC = () => <styled.Wrapper />
