import { Box } from '@mui/material'
import React, { useContext, useMemo } from 'react'

import { AnyVariableInput, CommonPicker, getFieldIcon, Icon, Name } from 'components'
import { ProjectContext } from 'context'
import { RightSidebarItem, SettingField, SubWrapper } from 'partials/RightSidebar/components'
import { Action, LocalVariable, Screen, ScreenInputParameter } from 'utils'

export const isParametersNotValid = (parameters?: ScreenInputParameter[]) =>
  !!parameters?.find(el => !el.parameter || !el.getter)

interface Props {
  screenName: string
  parameters?: ScreenInputParameter[]
  setAction: React.Dispatch<React.SetStateAction<Action>>
  screenConfig: Screen
  allLocalVariables: LocalVariable[]
}

export const Parameters: React.FC<Props> = ({ screenName, parameters, setAction, screenConfig, allLocalVariables }) => {
  const {
    state: { screens = [] },
  } = useContext(ProjectContext)

  const parametersNamesValues = useMemo(
    () =>
      screens
        .find(el => el.screenName === screenName)
        ?.inputParameters?.map(el => ({
          value: el.parameter,
          label: el.parameter,
          iconName: getFieldIcon(el.type),
          type: el.type,
          accept: el.accept,
        })) || [],
    [screenName, JSON.stringify(screens)]
  )

  const onAdd = () =>
    setAction(action => ({
      ...action,
      parameters: [...(action.parameters || []), {}],
    }))

  const onRemove = (index: number) =>
    setAction(action => {
      const copy = JSON.parse(JSON.stringify(action)) as Action
      copy.parameters?.splice(index, 1)
      if (copy.parameters && !copy.parameters.length) {
        delete copy.parameters
      }
      return copy
    })

  const onChange = (index: number, parameter: ScreenInputParameter) => {
    setAction(action => {
      const copy = JSON.parse(JSON.stringify(action)) as Action
      copy.parameters?.splice(index, 1, parameter)
      return copy
    })
  }

  return (
    <SubWrapper small noBorderBottom title="Parameters" onAdd={onAdd} canHide={!!parameters?.length} childrenFor="">
      {parameters && (
        <Box display="flex" flexDirection="column" gap="12px">
          {parameters.map((el, i) => (
            <RightSidebarItem key={`${el.parameter}${i}`} small title="">
              <SubWrapper
                small
                title="PARAMETER"
                childrenFor=""
                customIcon={<Icon name={Name.RIGHT_SIDEBAR_DELETE} onClick={() => onRemove(i)} />}
              >
                <SettingField
                  insidePicker
                  subLabel="Name"
                  value={el.parameter}
                  small
                  optionsContainer={
                    <CommonPicker
                      title="Names"
                      value={el.parameter}
                      onChange={value => onChange(i, { parameter: value })}
                      values={parametersNamesValues}
                      close={() => {}}
                    />
                  }
                />
                <AnyVariableInput
                  valueType={parametersNamesValues.find(a => a.value === el.parameter)?.type}
                  anyVariableValue={el.getter}
                  onChange={value => onChange(i, { ...el, getter: value })}
                  screenConfig={screenConfig}
                  allLocalVariables={allLocalVariables}
                />
              </SubWrapper>
            </RightSidebarItem>
          ))}
        </Box>
      )}
    </SubWrapper>
  )
}
