import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Button, ButtonColor, ButtonVariant, Icon, Name } from 'components'
import { TopPanelContext } from 'context'
import { CMSCollection, isSubCollection } from 'services/cms'
import { generateFirestoreId } from 'utils'
import { Actions } from '../components'
import { AddBtn } from './components'

export const saveSchemaEvent = 'saveSchemaEvent'

interface Props {
  isSchemaPage: boolean
  setIsSchemaPage: React.Dispatch<React.SetStateAction<boolean>>
  collectionId: string
  recordId: string
  currentCollection?: CMSCollection
  currentFolder?: CMSCollection
}

export const TopPanelCMS: React.FC<Props> = ({
  isSchemaPage,
  setIsSchemaPage,
  collectionId,
  recordId,
  currentCollection,
  currentFolder,
}) => {
  const { setLeftButtons, setRightButtons } = useContext(TopPanelContext)
  const navigate = useNavigate()
  const { id } = useParams()
  const isAddRecordDisabled = isSubCollection(collectionId) && collectionId === currentCollection?.id
  const [outerOpenAdd, setOuterOpenAdd] = useState(false)
  const [openActions, setOpenActions] = useState(false)

  const onAddRecord = () => {
    setIsSchemaPage(false)
    navigate(
      currentFolder
        ? `/projects/${id}/collections/${collectionId}/${generateFirestoreId()}?folder=${currentFolder.id}`
        : `/projects/${id}/collections/${collectionId}/${generateFirestoreId()}`
    )
  }

  const onSave = () => document.dispatchEvent(new Event(saveSchemaEvent))

  useEffect(() => {
    setLeftButtons(
      <>
        <AddBtn
          active={!!recordId && !openActions}
          disabled={isAddRecordDisabled}
          onClick={onAddRecord}
          currentFolder={currentFolder}
          setOuterOpenAdd={setOuterOpenAdd}
          collectionId={collectionId}
        />
        {!currentFolder && (
          <Button
            variant={ButtonVariant.TOP_PANEL_ACTION}
            leftIcon={<Icon name={Name.ADDITIONAL_EDIT_FIELDS} />}
            active={isSchemaPage && !openActions}
            onClick={() => setIsSchemaPage(isSchemaPage => !isSchemaPage)}
          >
            Edit fields
          </Button>
        )}
        <Button
          variant={ButtonVariant.TOP_PANEL_ACTION}
          leftIcon={<Icon name={Name.ADDITIONAL_ACTIONS} />}
          active={openActions}
          onClick={() => setOpenActions(open => !open)}
        >
          Actions
        </Button>
        {openActions && <Actions close={() => setOpenActions(false)} cms />}
      </>
    )
    return () => setLeftButtons(null)
  }, [currentFolder, collectionId, recordId, isSchemaPage, outerOpenAdd, openActions, isAddRecordDisabled])

  useEffect(() => {
    setRightButtons(
      isSchemaPage ? (
        <Button color={ButtonColor.OUTLINED} onClick={onSave}>
          Save schema
        </Button>
      ) : null
    )
    return () => setRightButtons(null)
  }, [isSchemaPage])

  return <></>
}
