import { Box } from '@mui/material'
import React, { useContext, useEffect, useRef, useState } from 'react'

import * as styled from './CustomSearch.styled'

import { FiltersPicker, IPickerItem, Icon, Name, getFieldIcon } from 'components'
import { ProjectContext } from 'context'
import { useOnClickOutside } from 'hooks'
import { CMSCollection, recordStatus } from 'services/cms'
import { CollectionProperty, ValueType, VariableSource } from 'utils'

interface Props {
  currentVariable: VariableSource
  setCurrentVariable: (val: React.SetStateAction<VariableSource>) => void
  properties: CollectionProperty[]
  currentFolder?: CMSCollection
  wide?: boolean
}

export const CustomSearch: React.FC<Props> = ({
  currentVariable,
  setCurrentVariable,
  properties,
  currentFolder,
  wide,
}) => {
  const { language } = useContext(ProjectContext)
  const [value, setValue] = useState(currentVariable.query?.search?.textConstant?.locales[language])
  const [openFilters, setOpenFilters] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, () => setOpenFilters(false))

  const currentVariableCollectionFieldsValues: IPickerItem[] = [
    ...(properties.map(el => ({
      value: el.name,
      label: el.name,
      iconName: getFieldIcon(el.type),
      type: el.type,
      accept: el.accept,
    })) || []),
    {
      value: recordStatus,
      label: recordStatus,
      iconName: Name.RIGHT_SIDEBAR_DEFAULT_ICON,
      type: ValueType.string,
    },
  ]

  useEffect(() => {
    const debounceFn = setTimeout(() => {
      setCurrentVariable(currentVariable => ({
        ...currentVariable,
        query: {
          ...currentVariable.query,
          search: value
            ? {
                textConstant: {
                  key: currentVariable.query?.search?.textConstant?.key || 'key',
                  locales: { ...currentVariable.query?.search?.textConstant?.locales, [language]: value },
                },
              }
            : undefined,
        },
      }))
    }, 600)
    return () => clearTimeout(debounceFn)
  }, [value])

  const filtersLength = currentVariable.query?.filters?.length

  return (
    <styled.CustomSearch ref={ref} wide={wide}>
      <input
        placeholder={`Type to search for ${currentFolder ? 'assets' : 'records'}`}
        value={value || ''}
        onChange={e => setValue(e.target.value)}
      />
      <styled.Filters onClick={() => setOpenFilters(open => !open)}>
        <Icon name={Name.RIGHT_SIDEBAR_FILTER} />
        Filters{!!filtersLength && ` (${filtersLength})`}
      </styled.Filters>
      {openFilters && (
        <Box position="absolute" top="100%" right="0" width="250px">
          <FiltersPicker
            close={() => setOpenFilters(false)}
            currentVariable={currentVariable}
            setCurrentVariable={val => setCurrentVariable(val as VariableSource)}
            currentVariableCollectionFieldsValues={currentVariableCollectionFieldsValues}
          />
        </Box>
      )}
    </styled.CustomSearch>
  )
}
