import { GetVariableValue } from 'hooks'
import { generateFirestoreId } from 'utils'
import {
  BrandingColorStyles,
  BrandingColorType,
  BrandingThemeType,
  ComponentType,
  ComponentsDirection,
  CoordinateConstant,
  InsetsConstant,
  LocalVariable,
  LocalizedContent,
  MediaVariable,
  Resource,
  Screen,
  ScreenComponent,
  StringVariable,
  TextAlignment,
  ValueType,
  VariableSource,
  VariableSourceType,
} from '../configTypes'

export const tabBarHeight = 49
export const topBarHeight = 44
export const screenHeight = 844
export const screenWidth = 390

export enum BarComponentType {
  tabBar = 'tabBar',
  tab = 'tab',
  topBar = 'topBar',
}

export const getComponentNameById = (id: string) => id.split('.').slice(1, -1).join('.')

export const getComponentTypeById = (id: string) => id.split('.')[0] as ComponentType | BarComponentType

export const getComponentId = (id: string) => id.split('.').slice(-1)[0] as string

export const isParentComponent = (componentType: ComponentType | BarComponentType) =>
  componentType === ComponentType.overlay ||
  componentType === ComponentType.scroll ||
  componentType === ComponentType.stack ||
  componentType === ComponentType.carousel ||
  componentType === ComponentType.list

export const getRealWidth = (getPxValue: (val?: string) => string, margins?: InsetsConstant) =>
  `calc(100% - (${getPxValue(margins?.leading)}px + ${getPxValue(margins?.trailing)}px))`

export const getRealHeight = (getPxValue: (val?: string) => string, margins?: InsetsConstant) =>
  `calc(100% - (${getPxValue(margins?.top)}px + ${getPxValue(margins?.bottom)}px))`

export enum ISize {
  buttonHeight = '@buttonHeight',
  cornersRadius = '@cornersRadius',
  max = '@max',
  topScreenMargins = '@topScreenMargins',
  trailingScreenMargins = '@trailingScreenMargins',
  bottomScreenMargins = '@bottomScreenMargins',
  leadingScreenMargins = '@leadingScreenMargins',
  thinSeparator = '@thinSeparator',
}

export const getPx =
  (hasTabBar: boolean, hasTopBar: boolean, safeAreaTop: number, safeAreaBottom: number) => (value?: string) => {
    switch (value) {
      case ISize.thinSeparator:
        return '1'
      case ISize.buttonHeight:
        return '50'
      case ISize.cornersRadius:
        return '8'
      case ISize.topScreenMargins:
        return `${safeAreaTop + (hasTopBar ? topBarHeight : 0)}`
      case ISize.trailingScreenMargins:
        return '0'
      case ISize.bottomScreenMargins:
        return `${safeAreaBottom + (hasTabBar ? tabBarHeight : 0)}`
      case ISize.leadingScreenMargins:
        return '0'
      case undefined:
        return '0'
      case '':
        return '0'
      default:
        return value
    }
  }

export enum TextAlign {
  START = 'start',
  CENTER = 'center',
  END = 'end',
}

export const getCSSTextAlignment = (val?: TextAlignment) => {
  switch (val) {
    case TextAlignment.leading:
      return TextAlign.START
    case TextAlignment.center:
      return TextAlign.CENTER
    case TextAlignment.trailing:
      return TextAlign.END
    default:
      return TextAlign.START
  }
}

export const getAppTextAlignment = (val?: TextAlign) => {
  switch (val) {
    case TextAlign.START:
      return TextAlignment.leading
    case TextAlign.CENTER:
      return TextAlignment.center
    case TextAlign.END:
      return TextAlignment.trailing
    default:
      return TextAlignment.leading
  }
}

export const getVariableCollectionName = (
  source: VariableSource,
  allLocalVariables: LocalVariable[]
): string | undefined => {
  const { type, collection, variableName } = source
  if (type === VariableSourceType.collection && collection) {
    return collection.name
  } else if (type === VariableSourceType.localVariable && variableName) {
    const localVariable = allLocalVariables.find(el => el.name === variableName)
    if (localVariable) {
      const { variable, collection } = localVariable
      return (
        collection ||
        (variable?.source
          ? variable.source.fieldName || getVariableCollectionName(variable.source, allLocalVariables)
          : undefined)
      )
    }
  }
}

export const getVariableName = (source: VariableSource) => {
  const { type, variableName, collection, componentName, fieldName, selector } = source
  return type === VariableSourceType.globalVariable && variableName
    ? fieldName
      ? `@${variableName}.${fieldName}`
      : `@${variableName}`
    : type === VariableSourceType.collection && collection
    ? fieldName
      ? selector
        ? `@${collection.name}/{id}.${fieldName}`
        : `@${collection.name}.${fieldName}`
      : selector
      ? `@${collection.name}/{id}`
      : `@${collection.name}`
    : type === VariableSourceType.component && componentName
    ? fieldName
      ? `@${componentName}.${fieldName}`
      : `@${componentName}`
    : type === VariableSourceType.localVariable && variableName
    ? fieldName
      ? `@${variableName}.${fieldName}`
      : `@${variableName}`
    : ''
}

export const getText = (language: string, text?: LocalizedContent) => {
  let key = 'key'
  let value = ''
  let hasLocale = false
  if (text) {
    key = text.key
    hasLocale = text.locales[language] !== undefined
    if (hasLocale) {
      value = text.locales[language]
    } else {
      value = key
    }
  }
  return { key, value, hasLocale }
}

export const getTextareaValue = (language: string, text?: LocalizedContent) => {
  let { key, value, hasLocale } = getText(language, text)
  if (!hasLocale) {
    return { key, value: '' }
  } else if (text) {
    const { values } = text
    if (values) {
      for (let i = 0; i < values.length; i++) {
        const index = i + 1
        value = value.replace(`$${index}`, variableFrame(getVariableName(values[i]), index))
      }
    }
  }
  return { key, value }
}

export const getTextValue = async (language: string, getVariableValue: GetVariableValue, text?: LocalizedContent) => {
  let { value, hasLocale } = getText(language, text)
  if (hasLocale && text) {
    const { values } = text
    if (values) {
      for (let i = 0; i < values.length; i++) {
        const index = i + 1
        value = value.replace(`$${index}`, await getVariableValue(values[i]))
      }
    }
  }
  return value
}

export const openVariablePickerEvent = 'openVariablePickerEvent'
const variableFrame = (name: string, index: number) =>
  `<div id="$${index}" contenteditable="false" onclick="document.dispatchEvent(new CustomEvent('${openVariablePickerEvent}', { detail: ${index} }))">${name}</div>`

export const cloudinaryUrl = 'https://res.cloudinary.com/codeplatform/image/upload'

export const getMediaResourceUrl = async (
  resources: Resource[],
  minWidth: number,
  getVariableValue: GetVariableValue,
  media?: MediaVariable,
  imageColor?: StringVariable,
  theme?: BrandingThemeType,
  colorStyles?: BrandingColorStyles
) => {
  const getUrl = async (source: VariableSource): Promise<string> => {
    const variableValue = await getVariableValue(source)
    try {
      return JSON.parse(variableValue).url
    } catch {
      return variableValue
    }
  }
  const mediaResource = media?.source
    ? {
        url: await getUrl(media.source),
        width: 0,
      }
    : resources.find(el => el.id === media?.constant?.resourceId)
  let url = mediaResource?.url || ''
  if (url.startsWith(cloudinaryUrl)) {
    url = url.replace('.svg', '.png')
    if (imageColor && colorStyles && theme) {
      const color = await getColor(imageColor, colorStyles, theme, getVariableValue)
      url = url.replace(cloudinaryUrl, `${cloudinaryUrl}/e_colorize,co_rgb:${color.slice(1)}`)
    }
    if (minWidth) {
      const urlParts = url.split('/')
      const crop = urlParts.find(el => el.includes('c_crop'))
      if (crop) {
        const cropIndex = urlParts.indexOf(crop)
        const cropParts = crop.split(',')
        const width = cropParts[2]?.replace('w_', '')
        const scale = Math.ceil(minWidth / +width)
        if (scale > 1) {
          urlParts.splice(cropIndex + 1, 0, `w_${scale}.0,c_scale`)
          url = urlParts.join('/')
        }
      } else if (mediaResource?.width) {
        const scale = Math.ceil(minWidth / mediaResource.width)
        if (scale > 1) {
          url = url.replace(cloudinaryUrl, `${cloudinaryUrl}/w_${scale}.0,c_scale`)
        }
      }
    }
  }
  return url
}

export const getColor = async (
  color: StringVariable,
  colorStyles: BrandingColorStyles,
  theme: BrandingThemeType,
  getVariableValue: GetVariableValue
) =>
  colorStyles[
    (color.source ? await getVariableValue(color.source) : (color.constant as string)).slice(1) as BrandingColorType
  ][theme]

export const findComponent = (
  screenConfig: Screen,
  propName: keyof ScreenComponent,
  value: string
): ScreenComponent | undefined => {
  const { components, topBar } = screenConfig
  const find = (components: ScreenComponent[]): ScreenComponent | undefined => {
    let component: ScreenComponent | undefined
    for (const el of components) {
      if (el[propName] === value) {
        component = el
      } else if (el.subComponents) {
        component = find(el.subComponents)
      }
      if (component) {
        return component
      }
    }
  }
  return find([
    ...(components || []),
    ...(topBar?.leadingComponents || []),
    ...(topBar?.headlineComponents || []),
    ...(topBar?.trailingComponents || []),
  ])
}

export const findAllScreenComponentsByType = (screenConfig: Screen, componentType: ComponentType) => {
  const { components, topBar } = screenConfig
  const componentsByType: ScreenComponent[] = []
  ;(function find(components: ScreenComponent[]) {
    componentsByType.push(...components.filter(el => el.componentType === componentType))
    components.forEach(el => {
      if (el.subComponents) {
        find(el.subComponents)
      }
    })
  })([
    ...(components || []),
    ...(topBar?.leadingComponents || []),
    ...(topBar?.headlineComponents || []),
    ...(topBar?.trailingComponents || []),
  ])
  return componentsByType
}

export const findAllScreenComponentsWichHasProp = (screenConfig: Screen, propName: keyof ScreenComponent) => {
  const { components, topBar } = screenConfig
  const componentsWichHasField: ScreenComponent[] = []
  ;(function find(components: ScreenComponent[]) {
    componentsWichHasField.push(...components.filter(el => !!el[propName]))
    components.forEach(el => {
      if (el.subComponents) {
        find(el.subComponents)
      }
    })
  })([
    ...(components || []),
    ...(topBar?.leadingComponents || []),
    ...(topBar?.headlineComponents || []),
    ...(topBar?.trailingComponents || []),
  ])
  return componentsWichHasField
}

export const findListItemBindedComponents = (components: ScreenComponent[], listItemContextKey: string) => {
  const bindedComponents: { field: string; component: ScreenComponent }[] = []
  ;(function find(components: ScreenComponent[]) {
    bindedComponents.push(
      ...components
        .filter(
          el =>
            el.text?.values?.[0]?.variableName === listItemContextKey ||
            el.image?.source?.variableName === listItemContextKey ||
            el.coordinate?.source?.variableName === listItemContextKey ||
            el.date?.source?.variableName === listItemContextKey ||
            el.displayDate?.source?.variableName === listItemContextKey
        )
        .map(el => ({
          field:
            el.text?.values?.[0]?.variableName === listItemContextKey
              ? 'text'
              : el.image?.source?.variableName === listItemContextKey
              ? 'image'
              : el.coordinate?.source?.variableName === listItemContextKey
              ? 'coordinate'
              : el.date?.source?.variableName === listItemContextKey
              ? 'date'
              : el.displayDate?.source?.variableName === listItemContextKey
              ? 'displayDate'
              : '',
          component: el,
        }))
    )
    components.forEach(el => {
      if (el.subComponents) {
        find(el.subComponents)
      }
    })
  })(components)
  return bindedComponents
}

interface TopBarComponentParent {
  id: string
  componentsDirection: ComponentsDirection
  topBarComponentParent: boolean
}

type ComponentParentData = {
  componentParent: Screen | ScreenComponent | TopBarComponentParent
  components: ScreenComponent[]
}

export const findComponentParentDataById = (
  componentParent: Screen | ScreenComponent,
  componentId: string
): ComponentParentData | undefined => {
  const components = (componentParent as Screen).components || (componentParent as ScreenComponent).subComponents || []
  const topBar = (componentParent as Screen).topBar
  if (topBar) {
    const { leadingComponents, headlineComponents, trailingComponents } = topBar
    const topBarComponentParent = {
      id: `${componentParent.id}-${BarComponentType.topBar}`,
      componentsDirection: ComponentsDirection.horizontal,
      topBarComponentParent: true,
    }
    if (leadingComponents) {
      if (leadingComponents.find(el => el.id === componentId)) {
        return { componentParent: topBarComponentParent, components: leadingComponents }
      } else {
        for (const el of leadingComponents) {
          const componentParentData = findComponentParentDataById(el, componentId)
          if (componentParentData) {
            return componentParentData
          }
        }
      }
    }
    if (headlineComponents) {
      if (headlineComponents.find(el => el.id === componentId)) {
        return { componentParent: topBarComponentParent, components: headlineComponents }
      } else {
        for (const el of headlineComponents) {
          const componentParentData = findComponentParentDataById(el, componentId)
          if (componentParentData) {
            return componentParentData
          }
        }
      }
    }
    if (trailingComponents) {
      if (trailingComponents.find(el => el.id === componentId)) {
        return { componentParent: topBarComponentParent, components: trailingComponents }
      } else {
        for (const el of trailingComponents) {
          const componentParentData = findComponentParentDataById(el, componentId)
          if (componentParentData) {
            return componentParentData
          }
        }
      }
    }
  }
  if (components.find(el => el.id === componentId)) {
    return { componentParent, components }
  } else {
    for (const el of components) {
      const componentParentData = findComponentParentDataById(el, componentId)
      if (componentParentData) {
        return componentParentData
      }
    }
  }
}

export const getAllLocalVariables = (
  screenConfig: Screen,
  screenOrComponent: Screen | ScreenComponent | TopBarComponentParent,
  forSubComponents?: boolean
) => {
  const allLocalVariables: LocalVariable[] = []
  if (forSubComponents) {
    const listItemContextKey = (screenOrComponent as ScreenComponent).listItemContextKey
    const listItems = (screenOrComponent as ScreenComponent).listItems
    if (listItemContextKey && listItems) {
      allLocalVariables.push({ name: listItemContextKey, variable: listItems, type: ValueType.record })
    }
  }
  const inputParameters = (screenOrComponent as Screen).inputParameters
  if (inputParameters) {
    allLocalVariables.push(...inputParameters.map(el => ({ ...el, name: el.parameter })))
  }
  const localVariables = (screenOrComponent as Screen | ScreenComponent).localVariables
  if (localVariables) {
    allLocalVariables.push(...localVariables)
  }
  const componentParentData = findComponentParentDataById(screenConfig, screenOrComponent.id)
  if (componentParentData) {
    const { componentParent } = componentParentData
    const listItemContextKey = (componentParent as ScreenComponent).listItemContextKey
    const listItems = (componentParent as ScreenComponent).listItems
    if (listItemContextKey && listItems) {
      allLocalVariables.push({ name: listItemContextKey, variable: listItems, type: ValueType.record })
    }
    if ((componentParent as TopBarComponentParent).topBarComponentParent) {
      allLocalVariables.push(...getAllLocalVariables(screenConfig, screenConfig))
    } else {
      allLocalVariables.push(...getAllLocalVariables(screenConfig, componentParent))
    }
  }
  return allLocalVariables
}

export const removeComponentById = (screenConfig: Screen, componentId: string) => {
  const remove = (components: ScreenComponent[], componentId: string): ScreenComponent[] =>
    components
      .filter(el => el.id !== componentId)
      .map(el => (el.subComponents ? { ...el, subComponents: remove(el.subComponents, componentId) } : el))
  screenConfig.components = remove(screenConfig.components || [], componentId)
  if (screenConfig.topBar?.leadingComponents) {
    screenConfig.topBar.leadingComponents = remove(screenConfig.topBar.leadingComponents, componentId)
  }
  if (screenConfig.topBar?.headlineComponents) {
    screenConfig.topBar.headlineComponents = remove(screenConfig.topBar.headlineComponents, componentId)
  }
  if (screenConfig.topBar?.trailingComponents) {
    screenConfig.topBar.trailingComponents = remove(screenConfig.topBar.trailingComponents, componentId)
  }
}

export const getElementIdFromConfig = (component: ScreenComponent) =>
  `${component.componentType}.${component.name || component.componentType}.${component.id}`

export const getComponentPositionFromName = (name?: string) => {
  const pos = name?.split(' ').slice(-1)
  return pos && !isNaN(+pos) ? +pos : 0
}

export const getNewComponentPosition = (screenConfig: Screen, componentType: ComponentType) =>
  getComponentPositionFromName(
    findAllScreenComponentsByType(screenConfig, componentType)
      .sort((a, b) => (getComponentPositionFromName(a.name) > getComponentPositionFromName(b.name) ? 1 : -1))
      .slice(-1)[0]?.name
  ) + 1

export const updateScreenTemplate = (screenConfig: Screen, templateScreen: Screen) => {
  templateScreen.id = screenConfig.id
  templateScreen.backgroundColor = screenConfig.backgroundColor
  return templateScreen
}

export const uniqueComponentId = (component: ScreenComponent) => {
  component.id = generateFirestoreId()
  component.subComponents?.forEach(el => uniqueComponentId(el))
}

export const coordinateHandler = (coordinate: CoordinateConstant, center: { lat: number; lng: number }) => {
  const lat = coordinate.latitude
  const lng = coordinate.longitude
  if (lat) {
    center.lat = +lat
  }
  if (lng) {
    center.lng = +lng
  }
  return center
}
