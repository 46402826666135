import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import ReactRouterPrompt from 'react-router-prompt'

import { Confirm } from 'components'
import { ProgressContext } from 'context'
import { saveSchemaEvent } from 'partials'
import { CMSCollection, CMSCollectionProperty, CMSService } from 'services/cms'
import { ValueType } from 'utils'
import { About, FieldSettings, Fields } from './components'

interface Props {
  collectionId: string
  currentCollection: CMSCollection
  setIsSchemaPage: React.Dispatch<React.SetStateAction<boolean>>
}

export const CollectionSchema: React.FC<Props> = ({ collectionId, currentCollection, setIsSchemaPage }) => {
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const { id } = useParams()
  const [properties, setProperties] = useState<CMSCollectionProperty[]>(
    currentCollection.properties?.map(el => ({ id: el.name, ...el })) || []
  )
  const [collectionBeforeEdit, setCollectionBeforeEdit] = useState('')
  const [datasource, setDatasource] = useState(currentCollection.datasource)
  const [helpText, setHelpText] = useState(currentCollection.helpText)
  const [titleField, setTitleField] = useState(currentCollection.titleField)
  const [previewScreen, setPreviewScreen] = useState(currentCollection.previewScreen)
  const [permissions, setPermissions] = useState(currentCollection.permissions)
  const [propertiesToRemoveIds, setPropertiesToRemoveIds] = useState<string[]>([])
  const [activePropertyId, setActivePropertyId] = useState('')
  const activeProperty = useMemo(
    () => properties.find(el => el.id === activePropertyId),
    [properties, activePropertyId]
  )
  const propertiesToSave = useMemo(
    () => properties.filter(el => !propertiesToRemoveIds.includes(el.id)),
    [properties, propertiesToRemoveIds]
  )
  const propertiesForTitleField = useMemo(
    () => propertiesToSave.filter(el => el.type === ValueType.string || el.type === ValueType.accessLevel),
    [propertiesToSave]
  )

  useEffect(() => {
    const datasource = currentCollection.datasource
    const helpText = currentCollection.helpText
    const titleField = currentCollection.titleField
    const previewScreen = currentCollection.previewScreen
    const permissions = currentCollection.permissions
    const properties = currentCollection.properties?.map(el => ({ id: el.name, ...el })) || []
    const propertiesToRemoveIds: string[] = []
    setDatasource(datasource)
    setHelpText(helpText)
    setTitleField(titleField)
    setPreviewScreen(previewScreen)
    setPermissions(permissions)
    setProperties(properties)
    setPropertiesToRemoveIds(propertiesToRemoveIds)
    setActivePropertyId('')
    setCollectionBeforeEdit(
      JSON.stringify({
        datasource,
        helpText,
        titleField,
        previewScreen,
        permissions,
        properties,
        propertiesToRemoveIds,
      })
    )
  }, [JSON.stringify(currentCollection)])

  useEffect(() => {
    const saveSchema = () => {
      const arrayPropertyWithoutAccept = propertiesToSave.find(el => el.type === ValueType.array && !el.accept)
      const keyValueMapPropertyWithoutDataObject = propertiesToSave.find(
        el => (el.type === ValueType.keyValueMap || el.accept === ValueType.keyValueMap) && !el.dataObject
      )
      const recordPropertyWithoutCollection = propertiesToSave.find(
        el => (el.type === ValueType.record || el.accept === ValueType.record) && !el.collection
      )
      const noPermissions =
        !permissions?.list || !permissions?.get || !permissions?.create || !permissions?.update || !permissions?.delete
      if (arrayPropertyWithoutAccept) {
        toast('Please fill "Accept" field')
        setActivePropertyId(arrayPropertyWithoutAccept.id)
      } else if (keyValueMapPropertyWithoutDataObject) {
        toast('Please fill "Data object" field')
        setActivePropertyId(keyValueMapPropertyWithoutDataObject.id)
      } else if (recordPropertyWithoutCollection) {
        toast('Please fill "Collection" field')
        setActivePropertyId(recordPropertyWithoutCollection.id)
      } else if (noPermissions) {
        toast('Please fill all Permissions')
        setActivePropertyId('')
      } else {
        startLoader()
        CMSService.updateCollection(
          id as string,
          collectionId,
          propertiesToSave.map((el, i) => {
            const copy = JSON.parse(JSON.stringify(el))
            delete copy.id
            return { ...copy, position: i }
          }),
          datasource,
          helpText,
          titleField,
          previewScreen,
          permissions
        )
          .catch(err => toast(err))
          .finally(() => stopLoader())
      }
    }
    document.addEventListener(saveSchemaEvent, saveSchema)
    return () => document.removeEventListener(saveSchemaEvent, saveSchema)
  }, [propertiesToSave, datasource, helpText, titleField, previewScreen, permissions])

  return (
    <>
      <ReactRouterPrompt
        when={
          !!collectionBeforeEdit &&
          collectionBeforeEdit !==
            JSON.stringify({
              datasource,
              helpText,
              titleField,
              previewScreen,
              permissions,
              properties,
              propertiesToRemoveIds,
            })
        }
      >
        {({ isActive, onConfirm, onCancel }) => <Confirm active={isActive} onConfirm={onConfirm} onCancel={onCancel} />}
      </ReactRouterPrompt>
      <Fields
        currentColection={currentCollection}
        properties={properties}
        setProperties={setProperties}
        propertiesToRemoveIds={propertiesToRemoveIds}
        setPropertiesToRemoveIds={setPropertiesToRemoveIds}
        activePropertyId={activePropertyId}
        setActivePropertyId={setActivePropertyId}
        setIsSchemaPage={setIsSchemaPage}
      />
      {activeProperty ? (
        <FieldSettings
          key={activeProperty.id}
          activeProperty={activeProperty}
          setActivePropertyId={setActivePropertyId}
          properties={properties}
          setProperties={setProperties}
        />
      ) : (
        <About
          collectionId={collectionId}
          helpText={helpText}
          setHelpText={setHelpText}
          titleField={titleField}
          setTitleField={setTitleField}
          previewScreen={previewScreen}
          setPreviewScreen={setPreviewScreen}
          permissions={permissions}
          setPermissions={setPermissions}
          propertiesForTitleField={propertiesForTitleField}
        />
      )}
    </>
  )
}
