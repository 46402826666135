import { Box } from '@mui/material'
import { memo, useState } from 'react'

import * as styled from 'components/Menu/Menu.styled'

import { DropDown, Icon, Name, Rotation } from 'components'
import { CMSCollection, rootFolderName } from 'services/cms'

export interface Branch extends CMSCollection {
  children: Branch[]
}

interface Props {
  folder: Branch
  currentFolder?: CMSCollection
  openRecords: (id: string) => void
  setToRemove: React.Dispatch<React.SetStateAction<CMSCollection | null>>
  lvl: number
}

export const FoldersItem: React.FC<Props> = memo(({ folder, currentFolder, openRecords, setToRemove, lvl }) => {
  const [open, setOpen] = useState(true)

  const options = [
    {
      label: 'View/edit assets',
      iconName: Name.PICKERS_EDIT_RECORDS,
      onClick: () => openRecords(folder.id),
    },
    {
      label: 'API documentation',
      iconName: Name.PICKERS_API_DOCUMENTATION,
    },
    {
      label: 'Delete folder',
      iconName: Name.PICKERS_DELETE,
      onClick: () => setToRemove(folder),
      red: true,
    },
  ].filter((el, i) => (folder.isSystem ? i !== 2 : true))

  return (
    <>
      <styled.NavItem
        active={currentFolder?.id === folder.id}
        onClick={() => openRecords(folder.id)}
        hasActions
        lvl={lvl}
        big
      >
        {!!folder.children.length ? (
          <styled.Arrow>
            <Icon
              onClick={e => {
                e.stopPropagation()
                setOpen(open => !open)
              }}
              name={Name.ADDITIONAL_NAVIGATION_TREE_ARROW}
              rotation={open ? Rotation.DOWN : undefined}
            />
          </styled.Arrow>
        ) : (
          lvl > 0 && <styled.Space />
        )}
        <Icon
          name={
            folder.name === rootFolderName
              ? Name.ADDITIONAL_HOME_FOLDER
              : folder.isSystem
              ? Name.REPOSITORY_SYSTEM_FOLDER
              : Name.ADDITIONAL_NAVIGATION_TREE_FOLDER
          }
        />
        <span>{folder.name}</span>
        <Box position="absolute" right="4px" onClick={e => e.stopPropagation()}>
          <DropDown
            options={options}
            top="15px"
            width="196px"
            right="-5px"
            scrollIntoView
            labelElement={
              <styled.ShowMoreActions>
                <Icon name={Name.RIGHT_SIDEBAR_SHOW_MORE} width={14} />
              </styled.ShowMoreActions>
            }
          />
        </Box>
      </styled.NavItem>
      {open &&
        folder.children.map(el => (
          <FoldersItem
            key={el.id}
            folder={el}
            currentFolder={currentFolder}
            openRecords={openRecords}
            setToRemove={setToRemove}
            lvl={lvl + 1}
          />
        ))}
    </>
  )
})
