import { Box } from '@mui/material'
import React, { useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ThemeContext } from 'styled-components'

import { Crumbs, CustomSearch, Icon, Name, Text, Type } from 'components'
import {
  CMSCollection,
  CMSCollectionProperty,
  getCollectionName,
  getParentCollectionName,
  isSubCollection,
  rootFolderName,
} from 'services/cms'
import { VariableSource } from 'utils'

interface Props {
  recordsLength: number
  currentVariable: VariableSource
  setCurrentVariable: (val: React.SetStateAction<VariableSource>) => void
  properties: CMSCollectionProperty[]
  collectionId: string
  currentFolder?: CMSCollection
}

export const CollectionRecordsTitle: React.FC<Props> = ({
  recordsLength,
  currentVariable,
  setCurrentVariable,
  properties,
  collectionId,
  currentFolder,
}) => {
  const themeContext = useContext(ThemeContext)
  const navigate = useNavigate()
  const { id } = useParams()

  return (
    <Box sx={{ position: 'sticky', left: '0', zIndex: '4' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        {isSubCollection(collectionId) ? (
          <Crumbs
            firstCrumbTitle={getCollectionName(getParentCollectionName(collectionId))}
            onFirstCrumbClick={() => navigate(`/projects/${id}/collections/${getParentCollectionName(collectionId)}`)}
            secondCrumbTitle={collectionId.split('/').slice(-2).join(' / ')}
          />
        ) : (
          <Crumbs
            firstCrumbTitle={collectionId}
            icon={
              <Icon
                name={
                  currentFolder
                    ? currentFolder.name === rootFolderName
                      ? Name.ADDITIONAL_HOME_FOLDER
                      : Name.ADDITIONAL_NAVIGATION_TREE_FOLDER
                    : Name.ADDITIONAL_COLLECTION
                }
              />
            }
          />
        )}
        <CustomSearch
          currentVariable={currentVariable}
          setCurrentVariable={setCurrentVariable}
          properties={properties}
          currentFolder={currentFolder}
        />
      </Box>
      <Text type={Type.SUB_TITLE} color={themeContext.colors.darkText1}>
        {recordsLength} {currentFolder ? 'assets' : 'records'} found
      </Text>
    </Box>
  )
}
