import { useFormik } from 'formik'
import React, { useContext } from 'react'

import { Crumbs, Icon, InputProperty, Name, RightForm, inputs, validate } from 'components'
import { ProjectContext } from 'context'
import { ConfigLogic, firstLetterUpperCase, ValueType } from 'utils'

interface Props {
  name: keyof ConfigLogic
  close: () => void
}

export const LogicItems: React.FC<Props> = ({ name, close }) => {
  const {
    state: { logic },
  } = useContext(ProjectContext)
  const items = logic[name]?.map(el => ({ id: el.name, ...el })) || []

  const properties: InputProperty[] = [
    {
      name,
      type: ValueType.array,
      accept: ValueType.logicItem,
      position: 0,
      hideLabel: true,
    },
  ]

  const formik = useFormik({
    initialValues: { [properties[0].name]: items } as any,
    enableReinitialize: true,
    validate: (data: any) => validate(data, properties),
    onSubmit: data => {},
  })

  return (
    <RightForm
      showConfirmWhen={false}
      onClose={close}
      title={
        <Crumbs
          firstCrumbTitle={firstLetterUpperCase(name)}
          onFirstCrumbClick={close}
          icon={<Icon name={Name.RIGHT_SIDEBAR_CLOSE} />}
          small
        />
      }
      form={<>{inputs(properties, formik)}</>}
    />
  )
}
