import React, { useContext } from 'react'

import { BooleanInput, ElementWithLocalizedContent } from 'components'
import { ProjectContext } from 'context'
import { RightSidebarItem, SettingTextField, SubWrapper } from 'partials/RightSidebar/components'
import { BarComponentType, LocalVariable, Screen, getTextareaValue } from 'utils'
import { Id } from '../common'

interface Props {
  onScreenPropertiesChange: (props: { propName: keyof Screen; value: any }[]) => void
  screenConfig: Screen
  allLocalVariables: LocalVariable[]
}

export const TopBarSetting: React.FC<Props> = ({ onScreenPropertiesChange, screenConfig, allLocalVariables }) => {
  const { language } = useContext(ProjectContext)
  const { showBackButton, screenTitle } = screenConfig

  const sectionName = 'TopBar'

  return (
    <RightSidebarItem title={sectionName} hasCloseIcon>
      <SubWrapper childrenFor={sectionName}>
        <Id value={BarComponentType.topBar} />
        <SettingTextField
          key={language}
          label="Screen title"
          value={getTextareaValue(language, screenTitle).value}
          values={screenTitle?.values || []}
          onChange={(value, values) =>
            onScreenPropertiesChange([
              {
                propName: 'screenTitle',
                value: {
                  key: getTextareaValue(language, screenTitle).key,
                  locales: { ...screenTitle?.locales, [language]: value },
                  values,
                },
              },
            ])
          }
          translation={
            screenTitle ? (
              <ElementWithLocalizedContent
                title="Screen title"
                value={screenTitle}
                onChange={value => onScreenPropertiesChange([{ propName: 'screenTitle', value }])}
              />
            ) : undefined
          }
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
      </SubWrapper>
      <SubWrapper title="Options" childrenFor={sectionName} canHide>
        <BooleanInput
          subLabel="Show back button"
          value={showBackButton?.constant}
          onChange={value => onScreenPropertiesChange([{ propName: 'showBackButton', value: { constant: value } }])}
          source={showBackButton?.source}
          onSourceChange={value => onScreenPropertiesChange([{ propName: 'showBackButton', value: { source: value } }])}
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
      </SubWrapper>
    </RightSidebarItem>
  )
}
