import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  ActionsIconTbodyWrapper,
  CustomSection,
  CustomSectionSubTitle,
  CustomSectionTitle,
  CustomSectionTitleAction,
  CustomSectionTitleWithActionWrapper,
  DropDown,
  Icon,
  Name,
  NoContent,
  TableWrapper,
} from 'components'
import { ProjectContext } from 'context'
import { ConfigLogic, firstLetterUpperCase } from 'utils'

interface LogicItemsTableProps {
  name: keyof ConfigLogic
  itemName: string
}

const LogicItemsTable: React.FC<LogicItemsTableProps> = ({ name, itemName }) => {
  const navigate = useNavigate()
  const {
    state: { logic },
  } = useContext(ProjectContext)
  const items = logic[name]?.map(el => ({ id: el.name, ...el })) || []

  return (
    <TableWrapper empty={!items.length} big>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{firstLetterUpperCase(itemName)} name</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        {!!items.length ? (
          <TableBody>
            {items.map(el => (
              <TableRow key={el.id} onClick={() => navigate(`${name}/${el.id}`)}>
                <TableCell>{el.name}</TableCell>
                <TableCell onClick={e => e.stopPropagation()}>
                  <ActionsIconTbodyWrapper>
                    <div>
                      <DropDown
                        options={[
                          {
                            label: `Delete ${itemName}`,
                            iconName: Name.PICKERS_DELETE,
                            onClick: () => {},
                            red: true,
                          },
                        ]}
                        top="25px"
                        labelElement={
                          <Box display="flex" alignItems="center">
                            <Icon name={Name.RIGHT_SIDEBAR_SHOW_MORE} />
                          </Box>
                        }
                      />
                    </div>
                  </ActionsIconTbodyWrapper>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        ) : (
          <NoContent isTable text="Empty" />
        )}
      </Table>
    </TableWrapper>
  )
}

interface Props {
  name: keyof ConfigLogic
}

export const LogicItems: React.FC<Props> = ({ name }) => {
  const title = firstLetterUpperCase(name)
  const itemName = name.slice(0, -1)
  const subTitle =
    name === 'flows'
      ? 'Create and manage flows to control the sequence of operations in your application.'
      : 'Add and customize prompts to enhance user interaction within your application.'

  return (
    <CustomSection>
      <CustomSectionTitleWithActionWrapper mb="10px">
        <CustomSectionTitle>{title}</CustomSectionTitle>
        <CustomSectionTitleAction to={`${name}/add`}>
          <Icon name={Name.RIGHT_SIDEBAR_ADD} />
          Add new {itemName}
        </CustomSectionTitleAction>
      </CustomSectionTitleWithActionWrapper>
      <CustomSectionSubTitle>{subTitle}</CustomSectionSubTitle>
      <LogicItemsTable name={name} itemName={itemName} />
    </CustomSection>
  )
}
