import React from 'react'

import { CustomSection, CustomSectionsWrapper, NoContent, Text, Type } from 'components'
import { Links, SettingsLayout } from '../components'

export const Github: React.FC = () => (
  <SettingsLayout>
    <Text type={Type.TITLE}>Github</Text>
    <Text type={Type.SUB_TITLE}>
      The product page for this app version will be published on the App Store, Google Play, and App Landing page with
      the assets and metadata below.
    </Text>
    <Links />
    <CustomSectionsWrapper>
      <CustomSection>
        <NoContent
          title="Lorem Ipsum is simply dummy text of the printing"
          text="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout."
          buttonTitle="Button"
          onButtonClick={() => {}}
          secondButtonTitle="Lorem Ipsum"
        />
      </CustomSection>
    </CustomSectionsWrapper>
  </SettingsLayout>
)
