import { Box } from '@mui/material'
import { useFormik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Button, Crumbs, Icon, InputProperty, Name, RightForm, inputs, validate } from 'components'
import { ProgressContext, ProjectContext } from 'context'
import { CMSCollectionRecord } from 'services/cms'
import { ConfigLogic, firstLetterUpperCase, ValueType } from 'utils'

const properties: InputProperty[] = [
  {
    name: 'sourceCode',
    type: ValueType.typescript,
    position: 0,
    isRequired: true,
    hideLabel: true,
  },
]

interface Props {
  name: keyof ConfigLogic
  recordId?: string
  onAddRef?: (val: any) => void
  closeAddRef?: () => void
}

export const LogicItem: React.FC<Props> = ({ name, recordId, onAddRef, closeAddRef }) => {
  const title = firstLetterUpperCase(name)
  const itemName = name.slice(0, -1)
  const navigate = useNavigate()
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const {
    state: { logic },
  } = useContext(ProjectContext)
  const items = logic[name]?.map(el => ({ id: el.name, ...el })) || []
  const { id, logicItemId = recordId } = useParams()
  const [wait, setWait] = useState(true)
  const [valuesBeforeEdit, setValuesBeforeEdit] = useState('')
  const [saveLoading, setSaveLoading] = useState(false)

  const setForm = async () => {
    const logicItem: CMSCollectionRecord = {}
    setWait(true)
    if (logicItemId) {
      const res = items.find(el => el.id === logicItemId) as CMSCollectionRecord | undefined
      if (res) {
        properties.forEach(property => {
          logicItem[property.name] = res[property.name] || null
        })
      } else {
        toast(`${firstLetterUpperCase(itemName)} not found`)
      }
    } else {
      properties.forEach(property => {
        logicItem[property.name] = null
      })
    }
    setValuesBeforeEdit(JSON.stringify(logicItem))
    formik.setValues({ ...logicItem })
    setWait(false)
  }

  useEffect(() => {
    setForm()
  }, [])

  const formik = useFormik({
    initialValues: {} as any,
    enableReinitialize: true,
    validate: (data: any) => validate(data, properties),
    onSubmit: data => {
      setSaveLoading(true)
      startLoader()
      // ;(flowId
      //   ? ProjectsService.updateProduct(id as string, flowId, data)
      //   : ProjectsService.postProduct(id as string, data)
      // )
      //   .then(res => {
      //     const flowId = res.data.id
      //     setValuesBeforeEdit(JSON.stringify(data))
      //     setTimeout(() => {
      //       navigate(`/projects/${id}/settings/logic/flows/${flowId}`)
      //     }, 0)
      //   })
      //   .catch(err => toast(err))
      //   .finally(() => {
      stopLoader()
      setSaveLoading(false)
      // })
    },
  })

  const runFormik = useFormik({
    initialValues: {} as any,
    enableReinitialize: true,
    validate: (data: any) => validate(data, runProperties),
    onSubmit: data => {},
  })

  const runProperties: InputProperty[] = [
    {
      name: 'input',
      type: ValueType.json,
      position: 0,
      onRun: runFormik.submitForm,
    },
    {
      name: 'output',
      type: ValueType.json,
      position: 1,
    },
  ]

  const showConfirmWhen = !!valuesBeforeEdit && valuesBeforeEdit !== JSON.stringify(formik.values)
  const closePath = `/projects/${id}/settings/logic`

  return (
    <RightForm
      wide
      showConfirmWhen={showConfirmWhen}
      onClose={() => (closeAddRef ? closeAddRef() : navigate(closePath))}
      title={
        <>
          {onAddRef ? (
            <Crumbs
              firstCrumbTitle={title}
              onFirstCrumbClick={closeAddRef}
              secondCrumbTitle={`${logicItemId ? 'Edit' : 'Add'} ${itemName}`}
              small
            />
          ) : (
            <Crumbs
              firstCrumbTitle={title}
              secondCrumbTitle={`${logicItemId ? 'Edit' : 'Add'} ${itemName}`}
              icon={<Icon name={Name.RIGHT_SIDEBAR_CLOSE} />}
              to={closePath}
              small
            />
          )}
          <Button onClick={formik.submitForm} disabled={!showConfirmWhen || saveLoading} loading={saveLoading}>
            Save
          </Button>
        </>
      }
      form={
        wait ? (
          <></>
        ) : (
          <Box display="flex" gap="20px">
            <Box width="710px">{inputs(properties, formik)}</Box>
            <Box width="296px">{inputs(runProperties, runFormik)}</Box>
          </Box>
        )
      }
    />
  )
}
