import { Box } from '@mui/material'
import React, { ReactElement, useContext, useEffect, useMemo, useState } from 'react'

import {
  ActionTransforms,
  AnyVariableField,
  Button,
  ButtonColor,
  CustomPicker,
  isParametersNotValid,
  isRecordModificationsNotValid,
  isTransformsNotValid,
  Parameters,
  RecordModifications,
  SelectStringValueField,
  StringField,
  SubActions,
  VariableField,
} from 'components'
import { ProjectContext } from 'context'
import {
  Action,
  ActionType,
  ComponentType,
  findAllScreenComponentsByType,
  LocalVariable,
  Screen,
  ValueType,
  VariableSourceType,
} from 'utils'

const buttonPadding = { padding: '9.5px' }

interface Props {
  close: () => void
  value: Action
  onChange: (val: Action) => void
  onRemove?: () => void
  screenConfig: Screen
  allLocalVariables: LocalVariable[]
}

export const ActionPicker: React.FC<Props> = ({
  close,
  value,
  onChange,
  onRemove,
  screenConfig,
  allLocalVariables,
}) => {
  const {
    state: { screens = [] },
  } = useContext(ProjectContext)
  const [subPicker, setSubPicker] = useState<ReactElement | null>(null)
  const [action, setAction] = useState(JSON.parse(JSON.stringify(value)) as Action)
  const {
    options,
    // enabled,
    actionType,
    parameters,
    collection,
    record,
    recordModifications,
    // actionArguments,
    componentName,
    variable,
    valueTarget,
    transforms,
    variableContextKey,
    // description,
    subActions,
  } = action
  const screenNames = useMemo(() => screens.map(el => el.screenName), [screens])
  const scrollComponentsValues = useMemo(() => {
    if (actionType === ActionType.scrollToStart) {
      const components = [
        ...findAllScreenComponentsByType(screenConfig, ComponentType.list),
        ...findAllScreenComponentsByType(screenConfig, ComponentType.scroll),
      ]
      return components.map(el => el.name).filter(el => !!el)
    }
    return []
  }, [screenConfig, actionType])

  useEffect(() => {
    setAction(JSON.parse(JSON.stringify(value)) as Action)
  }, [value])

  const onConfirm = () => {
    onChange(action)
    close()
  }

  const onDelete =
    value && onRemove
      ? () => {
          onRemove()
          close()
        }
      : undefined

  const confirmDisabled =
    !actionType ||
    (actionType === ActionType.checkAuthentication && (!options?.defaultTarget || !options?.successTarget)) ||
    ((actionType === ActionType.navigate || actionType === ActionType.show) && !options?.defaultTarget) ||
    (actionType === ActionType.verifyAccess && (!options?.defaultTarget || !variable?.accessLevelConstant)) ||
    (actionType === ActionType.subscribe && (!variable?.source || !variableContextKey || !subActions)) ||
    (actionType === ActionType.createRecord && (!collection || !recordModifications)) ||
    (actionType === ActionType.updateRecord && (!record || !recordModifications)) ||
    (actionType === ActionType.deleteRecord && !record) ||
    (actionType === ActionType.openWeb && !options?.link?.constant) ||
    (actionType === ActionType.openLink && !options?.link?.source) ||
    (actionType === ActionType.openEmail && !options?.targetEmail) ||
    (actionType === ActionType.scrollToStart && !componentName) ||
    (actionType === ActionType.setValue && (!valueTarget || !variable)) ||
    isParametersNotValid(parameters) ||
    isRecordModificationsNotValid(recordModifications) ||
    isTransformsNotValid(transforms)

  return (
    <CustomPicker
      title="Actions"
      close={close}
      optionsContainer={
        <Box maxHeight="500px" overflow="auto">
          {subPicker || (
            <>
              <SelectStringValueField
                label="Action"
                value={actionType}
                onChange={value => setAction({ actionType: value as ActionType })}
                setSubPicker={setSubPicker}
                options={Object.values(ActionType)}
              />
              {actionType === ActionType.checkAuthentication && (
                <>
                  <SelectStringValueField
                    label="Default target"
                    value={options?.defaultTarget}
                    onChange={value =>
                      setAction(action => ({ ...action, options: { ...action.options, defaultTarget: value } }))
                    }
                    setSubPicker={setSubPicker}
                    options={screenNames}
                  />
                  <SelectStringValueField
                    label="Success target"
                    value={options?.successTarget}
                    onChange={value =>
                      setAction(action => ({ ...action, options: { ...action.options, successTarget: value } }))
                    }
                    setSubPicker={setSubPicker}
                    options={screenNames}
                  />
                </>
              )}
              {(actionType === ActionType.navigate ||
                actionType === ActionType.navigateBack ||
                actionType === ActionType.show ||
                actionType === ActionType.verifyAccess) && (
                <SelectStringValueField
                  label="Target"
                  value={options?.defaultTarget}
                  onChange={value =>
                    setAction(action => ({ ...action, options: { ...action.options, defaultTarget: value } }))
                  }
                  setSubPicker={setSubPicker}
                  options={screenNames}
                />
              )}
              {(actionType === ActionType.navigate || actionType === ActionType.navigateBack) &&
                options?.defaultTarget && (
                  <Parameters
                    screenName={options.defaultTarget}
                    parameters={parameters}
                    setAction={setAction}
                    screenConfig={screenConfig}
                    allLocalVariables={allLocalVariables}
                  />
                )}
              {actionType === ActionType.verifyAccess && (
                <StringField
                  label="Access level"
                  value={variable?.accessLevelConstant}
                  onChange={value => setAction(action => ({ ...action, variable: { accessLevelConstant: value } }))}
                  setSubPicker={setSubPicker}
                />
              )}
              {actionType === ActionType.subscribe && (
                <>
                  <VariableField
                    label="Source"
                    source={variable?.source}
                    onSourceChange={value => setAction(action => ({ ...action, variable: { source: value } }))}
                    setSubPicker={setSubPicker}
                    dataMode
                    screenConfig={screenConfig}
                    allLocalVariables={allLocalVariables}
                  />
                  <StringField
                    label="Context key"
                    value={variableContextKey}
                    onChange={value => setAction(action => ({ ...action, variableContextKey: value }))}
                    setSubPicker={setSubPicker}
                  />
                  <SubActions
                    label="Sub actions"
                    value={subActions}
                    onChange={value => setAction(action => ({ ...action, subActions: value }))}
                    setSubPicker={setSubPicker}
                    screenConfig={screenConfig}
                    allLocalVariables={allLocalVariables}
                  />
                </>
              )}
              {actionType === ActionType.createRecord && (
                <VariableField
                  label="Collection"
                  source={{ type: VariableSourceType.collection, collection }}
                  onSourceChange={value => setAction(action => ({ ...action, collection: value?.collection }))}
                  setSubPicker={setSubPicker}
                  collectionMode
                  screenConfig={screenConfig}
                  allLocalVariables={allLocalVariables}
                />
              )}
              {(actionType === ActionType.updateRecord || actionType === ActionType.deleteRecord) && (
                <VariableField
                  label="Record"
                  source={record}
                  onSourceChange={value => setAction(action => ({ ...action, record: value }))}
                  setSubPicker={setSubPicker}
                  recordMode
                  screenConfig={screenConfig}
                  allLocalVariables={allLocalVariables}
                />
              )}
              {(actionType === ActionType.updateRecord || actionType === ActionType.createRecord) &&
                (collection || record) && (
                  <RecordModifications
                    collection={collection}
                    record={record}
                    recordModifications={recordModifications}
                    setAction={setAction}
                    screenConfig={screenConfig}
                    allLocalVariables={allLocalVariables}
                  />
                )}
              {(actionType === ActionType.getPhoto ||
                actionType === ActionType.getVideo ||
                actionType === ActionType.getFile ||
                actionType === ActionType.setValue) && (
                <VariableField
                  label="Value target"
                  source={valueTarget}
                  onSourceChange={value => setAction(action => ({ ...action, valueTarget: value }))}
                  setSubPicker={setSubPicker}
                  screenConfig={screenConfig}
                  allLocalVariables={allLocalVariables}
                />
              )}
              {(actionType === ActionType.getPhoto ||
                actionType === ActionType.getVideo ||
                actionType === ActionType.getFile) && (
                <ActionTransforms
                  transforms={transforms}
                  onChange={value => setAction(action => ({ ...action, transforms: value }))}
                  screenConfig={screenConfig}
                  allLocalVariables={allLocalVariables}
                />
              )}
              {actionType === ActionType.openWeb && (
                <StringField
                  label="Link"
                  value={options?.link?.constant}
                  onChange={value =>
                    setAction(action => ({ ...action, options: { ...action.options, link: { constant: value } } }))
                  }
                  setSubPicker={setSubPicker}
                />
              )}
              {actionType === ActionType.openLink && (
                <VariableField
                  label="Link"
                  source={options?.link?.source}
                  onSourceChange={value =>
                    setAction(action => ({ ...action, options: { ...action.options, link: { source: value } } }))
                  }
                  setSubPicker={setSubPicker}
                  screenConfig={screenConfig}
                  allLocalVariables={allLocalVariables}
                />
              )}
              {actionType === ActionType.openEmail && (
                <StringField
                  label="Target email"
                  value={options?.targetEmail}
                  onChange={value =>
                    setAction(action => ({ ...action, options: { ...action.options, targetEmail: value } }))
                  }
                  setSubPicker={setSubPicker}
                />
              )}
              {actionType === ActionType.scrollToStart && (
                <SelectStringValueField
                  label="Component name"
                  value={componentName}
                  onChange={value => setAction(action => ({ ...action, componentName: value }))}
                  setSubPicker={setSubPicker}
                  options={scrollComponentsValues}
                />
              )}
              {actionType === ActionType.setValue && (
                <AnyVariableField
                  valueType={ValueType.string}
                  label="Value"
                  value={variable}
                  onChange={value => setAction(action => ({ ...action, variable: value }))}
                  setSubPicker={setSubPicker}
                  screenConfig={screenConfig}
                  allLocalVariables={allLocalVariables}
                />
              )}
              <Box
                padding="15px 10px"
                display="flex"
                justifyContent="space-between"
                gap="8px"
                flexWrap="wrap-reverse"
                width="100%"
              >
                {onDelete ? (
                  <Button style={buttonPadding} color={ButtonColor.DELETE} onClick={onDelete}>
                    Remove
                  </Button>
                ) : (
                  <span />
                )}
                <Box display="flex" gap="8px">
                  <Button style={buttonPadding} color={ButtonColor.SECONDARY} onClick={close}>
                    Cancel
                  </Button>
                  <Button style={buttonPadding} onClick={onConfirm} disabled={confirmDisabled}>
                    Confirm
                  </Button>
                </Box>
              </Box>
            </>
          )}
        </Box>
      }
    />
  )
}
