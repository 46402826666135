import { ReactElement, useState } from 'react'

import { TopPanelContext } from 'context'
import { ConfigLogic } from 'utils'

interface Props {
  children: React.ReactNode
}

export const TopPanelProvider: React.FC<Props> = ({ children }) => {
  const [backButton, setBackButton] = useState<ReactElement | null>(null)
  const [leftButtons, setLeftButtons] = useState<ReactElement | null>(null)
  const [pageLeftButtons, setPageLeftButtons] = useState<ReactElement | null>(null)
  const [pageCenterButtons, setPageCenterButtons] = useState<ReactElement | null>(null)
  const [rightButtons, setRightButtons] = useState<ReactElement | null>(null)
  const [page, setPage] = useState<ReactElement | null>(null)
  const [openStoreManager, setOpenStoreManager] = useState(false)
  const [openLogicItems, setOpenLogicItems] = useState<keyof ConfigLogic | null>(null)

  return (
    <TopPanelContext.Provider
      value={{
        backButton,
        setBackButton,
        leftButtons,
        setLeftButtons,
        pageLeftButtons,
        setPageLeftButtons,
        pageCenterButtons,
        setPageCenterButtons,
        rightButtons,
        setRightButtons,
        page,
        setPage,
        openStoreManager,
        setOpenStoreManager,
        openLogicItems,
        setOpenLogicItems,
      }}
    >
      {children}
    </TopPanelContext.Provider>
  )
}
