import { memo } from 'react'
import AceEditor from 'react-ace'

import { SimpleJsonInputWrapper } from '../../FieldTypes.styled'

import { FieldProps } from '../../types'

interface Props extends FieldProps {
  value: string | null
  onChange: (val: string | null) => void
}

export const SimpleJsonInput = memo((props: Props) => (
  <SimpleJsonInputWrapper>
    <div>JSON editor</div>
    <AceEditor
      value={props.value || undefined}
      onChange={value => props.onChange(value || null)}
      mode="json"
      width="100%"
      height="300px"
    />
  </SimpleJsonInputWrapper>
))
